import { Client } from '@/app/lib/client/client';
import { Constants } from '@/app/lib/Constants';
import type { Thread } from '@/app/lib/entities/Thread.entity';
import { Text } from '@/app/lib/helpers/Text';
import { tabNames, tabs } from '@/app/lib/types/Workspace';
import { useSocketContext } from '@/app/socket-context';
import { Modal } from '@/components/modal';
import NextLink from 'next/link';
import { useState, type MouseEvent, type ReactNode } from 'react';

export interface Props {
	thread: Thread;
	path: string;
	children: ReactNode;
}

export function Workspace(props: Props) {
	const { thread, children, path } = props;
	const isUserThread = thread?.threadType == 'user';
	const projectId = thread.projectId;
	const { user } = useSocketContext();

	const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
	const [selectedTab, setSelectedTab] = useState<string | undefined>(
		tabs.find((tabName) => document.location.pathname.includes(`${path}/${tabName}`)),
	);

	const openInviteModal = () => {
		setIsInviteModalOpen(true);
	};
	const closeInviteModal = () => {
		setIsInviteModalOpen(false);
	};
	const projectRoleChange = (e: MouseEvent<HTMLInputElement>) => {
		if (user) {
			Client.Emit({
				type: 'thread-role-change',
				cancellationToken: Text.RandomString(),
				role: thread.userProjectRole == 'owner' ? 'engineer' : 'owner',
				threadId: thread.id,
				userId: user.id,
			});
		}
		e.preventDefault();
		return false;
	};

	return (
		<>
			<div className='flex flex-col flex-grow bg-white'>
				<div className='flex flex-row items-center flex-shrink-0 h-16 bg-white border-b border-gray-300 px-4 justify-between'>
					<div>
						<h1 className='text-sm font-bold max-w-56 overflow-ellipsis whitespace-nowrap overflow-hidden h-4 mr-2'>{thread?.name ?? ''}</h1>
					</div>
					<div className='flex items-center'>
						{tabs
							.filter((tabName) => {
								const isOk = isUserThread || tabName == 'chat';

								if (!isOk) {
									return false;
								}

								if (thread.userProjectRole == 'owner' && ['terminal', 'editor'].includes(tabName)) {
									return false;
								}

								return isOk;
							})
							.map((tabName, i) => (
								<NextLink
									href={`${path}/${tabName}`}
									onClick={() => {
										setSelectedTab(tabName);
									}}
									key={i}
									className={`text-sm font-semibold text-nowrap leading-none ${selectedTab == tabName ? 'bg-blue-200' : ''} hover:bg-gray-200 p-4 rounded-md mr-2`}>
									{tabNames[tabName]}
								</NextLink>
							))}
					</div>
					<div className='overflow-hidden whitespace-nowrap'>
						<div className='inline-block mx-2 align-middle'>
							<label className='inline-flex items-center cursor-pointer ' htmlFor='project-role'>
								<input
									id='project-role'
									type='checkbox'
									value='asdf'
									className='sr-only peer'
									onClick={projectRoleChange}
									checked={thread.userProjectRole != 'owner'}
									readOnly
								/>
								<div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
								<span
									className={`ms-3 text-sm font-medium ${thread.userProjectRole == 'owner' ? 'text-gray-400' : ''}`}>
									<strong>Dev Mode {thread.userProjectRole == 'owner' ? '(off)' : '(on)'}</strong>
								</span>
							</label>
						</div>
						<button
							type='button'
							onClick={openInviteModal}
							className='bg-blue-500 hover:bg-blue-700 text-white  focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg px-5 py-2.5 text-center'>
							Invite
						</button>
					</div>
				</div>

				<div className='flex flex-col flex-grow overflow-auto'>{children}</div>
			</div>
			<Modal title='Invite Collaborator' isOpen={isInviteModalOpen} onClose={closeInviteModal} showCloseButton={true}>
				<p>
					You can work with a colleague in {Constants.PRODUCT_NAME} on the same project. You and your colleagues will be
					able to have different conversations with the AI Dev Team about the same product and you will also be able to
					view and monitor builds triggered by team-members.
				</p>
				<br />
				<strong>How to use</strong>
				<ul style={{ margin: '0px 0px 0px 1rem' }}>
					<li className='list-disc'>Copy the link at the bottom of this pop-up</li>
					<li className='list-disc'>Give the link to your colleague</li>
					<li className='list-disc'>When your colleague visits the link they should start a new thread</li>
					<li className='list-disc'>No team-member can see the conversations of other team-members</li>
				</ul>
				<br />
				<strong>Invite Link</strong>
				<p>Copy the link below:</p>
				<pre className='code'>
					{window.location.origin}/invite/{projectId}
				</pre>
			</Modal>
		</>
	);
}

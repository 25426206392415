'use client';

import type { ThreadId } from '@/app/lib/entities/Thread.entity';
import { useSocketContext } from '@/app/socket-context';
import { SideBar } from '@/components/sideBar';
import { Workspace } from '@/components/workspace';
import { useEffect } from 'react';

export default function Layout({
	children,
	params,
}: Readonly<{
	children: React.ReactNode;
	params: { threadId: ThreadId };
}>) {
	const { threadId } = params;

	const socketData = useSocketContext();
	const { threads, user, selectThreadId } = socketData;
	useEffect(() => {
		if (user) {
			selectThreadId(threadId, user);
		}
	}, [user, threadId, selectThreadId]);

	const selectedThread = threads.find((t) => t.id == threadId);

	return (
		<>
			<SideBar selectedThreadId={threadId} />
			<>
				{selectedThread ? (
					<Workspace key={Math.random()} path={`/${threadId}`} thread={selectedThread}>
						{children}
					</Workspace>
				) : (
					<div className='flex flex-col flex-grow'>
						<div className='p-4'>
							<p className='loading'>Loading</p>
							<small>
								If this does not work then clear your Application localStorage in Chrome DevTools, you should delete the
								`session` object.
							</small>
						</div>
					</div>
				)}
			</>
		</>
	);
}

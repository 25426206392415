import { Client } from '@/app/lib/client/client';
import type { ProjectId } from '@/app/lib/entities/Project.entity';
import type { Thread, ThreadId } from '@/app/lib/entities/Thread.entity';
import { Text } from '@/app/lib/helpers/Text';
import { useSocketContext } from '@/app/socket-context';
import { Modal } from '@/components/modal';
import { type KeyboardEvent, type MouseEvent, useEffect, useState } from 'react';

export type ThreadEditModalState = 'add-new' | 'edit' | 'closed';

export interface Props {
	state: ThreadEditModalState;
	editingThreadId: ThreadId | undefined;
	onClose: () => void;
}

export function ModalThreadEdit(props: Props) {
	const { state, editingThreadId, onClose } = props;

	const [thread, setThread] = useState<Thread | undefined>(undefined);
	const [name, setName] = useState<string | undefined>(undefined);
	const [nameError, setNameError] = useState<string | undefined>();
	const [isFirstRender, setIsFirstRender] = useState<boolean>(true);

	const socketData = useSocketContext();
	const { threads, user } = socketData;
	const isModalOpen = state == 'add-new' || (state == 'edit' && !!editingThreadId);

	useEffect(() => {
		if (isModalOpen && isFirstRender) {
			document.getElementById('project-name')?.focus();
			setIsFirstRender(false);
			let thread: Thread | undefined = {
				threadType: 'user',
				name: 'New Project Name',
				datePosted: new Date(),
				id: undefined as unknown as ThreadId,
				userId: user?.id,
				isDeleted: false,
				projectId: undefined as unknown as ProjectId,
				userProjectRole: 'owner',
			};
			if (state == 'edit') {
				thread = threads.find((t) => t.id == editingThreadId);
			}
			setThread(thread);
			setName(thread?.name);
		}
	}, [isFirstRender, threads, state, isModalOpen, editingThreadId, user]);

	if (!isModalOpen) {
		return <></>;
	}

	const onModalClose = () => {
		setIsFirstRender(true);
		onClose();
	};

	const validate = () => {
		let isValid = true;
		setNameError(undefined);

		if (!name || name.length < 5) {
			isValid = false;
			setNameError('Too short');
		}

		return isValid;
	};

	const onClickSubmit = (e: MouseEvent | KeyboardEvent) => {
		if (thread && validate()) {
			thread.name = name ?? '';
			if (user) {
				if (state == 'add-new') {
					Client.Emit({
						type: 'thread-created',
						thread: { ...thread, userId: user.id },
						cancellationToken: Text.RandomString(),
						userId: user.id,
					});
				} else if (state == 'edit') {
					Client.Emit({
						type: 'thread-edited',
						thread: { ...thread, userId: user.id },
						cancellationToken: Text.RandomString(),
						userId: user.id,
					});
				}
			}
			onModalClose();
		}
		e.preventDefault();
		return false;
	};

	const footer = (
		<div className='flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b '>
			<button
				type='button'
				className='bg-blue-500 hover:bg-blue-700 text-white font-bold focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg px-5 py-2.5 text-center'
				onClick={onClickSubmit}>
				Submit
			</button>
		</div>
	);
	return (
		<Modal title='Project Details' isOpen={isModalOpen} onClose={onModalClose} footer={footer}>
			<div className='mb-6'>
				<label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='project-name'>
					Project Name
				</label>
				<input
					className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${nameError ? 'border-red-500 mb-3' : ''}`}
					id='project-name'
					type='text'
					placeholder='Your Project Name'
					value={name ?? ''}
					onKeyUp={(e) => {
						if (e.key === 'Enter') {
							onClickSubmit(e);
						}
						if (e.key === 'Escape') {
							onClose();
						}
					}}
					onChange={(e) => {
						setName(e.target.value);
					}}
				/>
				{nameError && <p className='text-red-500 text-xs italic'>{nameError}</p>}
			</div>
		</Modal>
	);
}

import { Client } from '@/app/lib/client/client';
import { Constants } from '@/app/lib/Constants';
import type { ThreadWithInfo } from '@/app/lib/dtos/ServerSocketEmit';
import type { ThreadId } from '@/app/lib/entities/Thread.entity';
import { Text } from '@/app/lib/helpers/Text';
import { useSocketContext } from '@/app/socket-context';
import { ModalThreadEdit, type ThreadEditModalState } from '@/components/modalThreadEdit';
import { SVGExpandArrow } from '@/components/svg/expandArrow';
import { SVGHamburger } from '@/components/svg/hamburger';
import { SVGHeavyPlus } from '@/components/svg/heavyPlus';
import NextLink from 'next/link';
import { type MouseEvent as ReactMouseEvent, useEffect, useMemo, useRef, useState } from 'react';

export interface Props {
	selectedThreadId: ThreadId;
}

export const SideBar = (props: Props) => {
	const selectedThreadId = props.selectedThreadId;
	const sidebarRef = useRef<HTMLDivElement>(null);
	const [isResizing, setIsResizing] = useState<boolean>(false);
	const [threadEditModalState, setsThreadEditModalState] = useState<ThreadEditModalState>('closed');
	const [editingThreadId, setsEditingThreadId] = useState<ThreadId | undefined>(undefined);
	const sidebarWidth = useRef(300);

	const socketData = useSocketContext();
	const { threads, user } = socketData;

	useEffect(() => {
		const stopResizing = () => {
			setIsResizing(false);
		};

		const resize = (mouseMoveEvent: MouseEvent) => {
			if (isResizing && sidebarRef.current) {
				sidebarWidth.current = mouseMoveEvent.clientX - 3;
				sidebarRef.current.style.width = `${String(sidebarWidth.current)}px`;
			}
		};

		window.addEventListener('mousemove', resize);
		window.addEventListener('mouseup', stopResizing);

		return () => {
			window.removeEventListener('mousemove', resize);
			window.removeEventListener('mouseup', stopResizing);
		};
	}, [isResizing]);

	return useMemo(() => {
		const startResizing = () => {
			setIsResizing(true);
		};

		const onEditThread = (thread: ThreadWithInfo) => {
			return (e: ReactMouseEvent) => {
				setsEditingThreadId(thread.id);
				setsThreadEditModalState('edit');
				e.preventDefault();
				return false;
			};
		};

		const onAddThread = (e: ReactMouseEvent) => {
			setsEditingThreadId(undefined);
			setsThreadEditModalState('add-new');
			e.preventDefault();
			return false;
		};

		const onDeleteThread = (thread: ThreadWithInfo) => {
			return (e: ReactMouseEvent) => {
				if (threads) {
					const index = threads.indexOf(thread);
					if (index >= 0 && user) {
						Client.Emit({
							type: 'thread-deleted',
							threadId: thread.id,
							cancellationToken: Text.RandomString(),
							userId: user.id,
						});
					}
				}
				e.preventDefault();
				return false;
			};
		};

		const onThreadEditModalClose = () => {
			setsThreadEditModalState('closed');
		};

		return (
			<>
				<div
					className='flex flex-col flex-shrink-0 w-64 border-r border-gray-300 bg-gray-100 min-w-56'
					style={{ width: sidebarWidth.current }}
					ref={sidebarRef}>
					<div className='flex-shrink-0 relative text-sm'>
						<div className='flex items-center justify-between w-full h-16 px-4'>
							<span className='font-medium'>{Constants.PRODUCT_NAME}</span>
						</div>
					</div>
					<div className='h-0 overflow-auto flex-grow'>
						<div className='mt-4'>
							<div className='flex items-center h-8 px-3 group'>
								<button id='Threads_toggle' className='flex items-center flex-grow focus:outline-none'>
									<SVGExpandArrow />
									<span className='ml-2 leading-none font-medium text-sm'>Projects</span>
								</button>
								<button
									className='flex items-center justify-center h-6 w-6 ml-1 rounded hover:bg-gray-300'
									onClick={onAddThread}>
									<SVGHeavyPlus />
								</button>
							</div>
							<div id='Threads_list'>
								{threads
									?.filter((thread) => thread.threadType == 'user')
									?.map((thread, i) => (
										<span
											key={i}
											className={`cursor-pointer flex group/thread relative items-center h-8 hover:bg-gray-300 text-sm pl-8 pr-3 ${thread.id == selectedThreadId ? 'bg-gray-300' : ''}`}>
											<NextLink className='w-full h-6 flex justify-between' href={`/${thread.id}`}>
												<span
													className={`ml-2 whitespace-nowrap overflow-hidden text-ellipsis ${thread.id == selectedThreadId ? 'font-bold' : ''}`}>
													{thread.name}
												</span>
												{thread.isBlocked && (
													<span className='rounded-2xl bg-red-400 inline-block py-1 px-3 mx-2 text-black font-normal text-xs'>
														blocked
													</span>
												)}
												{!thread.isBlocked && thread.isPaused && (
													<span className='rounded-2xl bg-orange-400 inline-block py-1 px-3 mx-2 text-black font-normal text-xs'>
														paused
													</span>
												)}
												{!thread.isBlocked && !thread.isPaused && thread.isWorking && (
													<span className='rounded-2xl bg-blue-400 inline-block py-1 px-3 mx-2 text-black font-normal text-xs'>
														working
													</span>
												)}
												{!thread.isBlocked && !thread.isPaused && !thread.isWorking && thread.isComplete && (
													<span className='rounded-2xl bg-green-400 inline-block py-1 px-3 mx-2 text-black font-normal text-xs'>
														complete
													</span>
												)}
											</NextLink>
											<button
												className='items-center justify-center h-6 w-6 ml-auto rounded flex hover:bg-gray-300 group/button'
												onClick={(e) => {
													e.preventDefault();
													return false;
												}}>
												<SVGHamburger />
												<div className='top-8 absolute z-10 flex-col items-start hidden w-full pb-1 bg-white shadow-lg group-focus/button:flex'>
													<span
														className='w-full px-4 py-2 text-left hover:bg-gray-300'
														onMouseDown={onEditThread(thread)}>
														Change Details
													</span>
													<span
														className='w-full px-4 py-2 text-left text-red-600 hover:bg-gray-300'
														onMouseDown={onDeleteThread(thread)}>
														Delete Thread
													</span>
												</div>
											</button>
										</span>
									))}
							</div>
						</div>
					</div>
				</div>
				<div
					className='flex-grow-0 flex-shrink-0 basis-2 justify-self-end cursor-col-resize resize-x hover:w-2 hover:bg-gray-600 select-none'
					onMouseDown={startResizing}
				/>
				<ModalThreadEdit
					state={threadEditModalState}
					onClose={onThreadEditModalClose}
					editingThreadId={editingThreadId}
				/>
			</>
		);
	}, [editingThreadId, selectedThreadId, threadEditModalState, threads, user]);
};

import { SVGCloseCross } from '@/components/svg/closeCross';
import type { MouseEvent, ReactNode } from 'react';

export interface Props {
	title: string;
	isOpen: boolean;
	onClose: () => void;
	children: ReactNode;
	footer?: ReactNode | undefined;
	showCloseButton?: boolean | undefined;
}

export function Modal(props: Props) {
	if (props.isOpen) {
		const closeModal = (e: MouseEvent<unknown, unknown>) => {
			props.onClose();
			e.preventDefault();
			return false;
		};

		return (
			<div
				tabIndex={-1}
				className={
					'flex bg-white bg-opacity-50 filter m-auto overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full'
				}
				onClick={closeModal}>
				<div
					className='relative p-4 w-full max-w-2xl max-h-full'
					onClick={(e) => {
						e.stopPropagation();
						// eslint-disable-next-line
						e.nativeEvent.stopImmediatePropagation();
						e.preventDefault();
					}}>
					<div className='relative bg-white rounded-lg shadow'>
						<div className='flex items-center justify-between p-4 md:p-5 border-b rounded-t '>
							<h3 className='text-xl font-semibold text-gray-900 '>{props.title}</h3>
							<button
								type='button'
								className='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center'
								onClick={closeModal}>
								<SVGCloseCross />
								<span className='sr-only'>Close modal</span>
							</button>
						</div>
						<div className='p-4 md:p-5 space-y-4 text-base leading-relaxed'>{props.children}</div>
						{props.showCloseButton && (
							<div className='flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b '>
								<button
									type='button'
									className='bg-blue-500 hover:bg-blue-700 text-white font-bold focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg px-5 py-2.5 text-center'
									onClick={closeModal}>
									Close
								</button>
							</div>
						)}
						{props.footer}
					</div>
				</div>
			</div>
		);
	}
	return <></>;
}

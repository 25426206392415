export const tabs = ['chat', 'designer', 'terminal', 'build', 'editor', 'preview'] as const;
export type TabType = (typeof tabs)[number];
export const tabNames: Record<TabType, string> = {
	designer: 'UI Designer',
	editor: 'File Editor',
	terminal: 'Shell',
	preview: 'Preview',
	chat: 'Chat',
	build: 'Builds',
};
